import * as React from "react";
import EvergreenBrands from "./evergreen-brands";
import Honey4Hope from "./honey4-hope";
import "./portfolio.css";

const Portfolio = () => {
  return (
    <section className="portfolio">
      <div className="portfolio-header">
        <h1 className="portfolio1" style={{ marginBottom: "24px" }}>
          Portfolio
        </h1>
        <div className="take-a-look">
          Take a look at Dave’s involvement and leadership for brands making an
          impact around North America.
        </div>
      </div>
      <div className="company-cards">
        <div className="project-card">
          <EvergreenBrands
            unsplash9anj7QWy2g="/unsplash9anj7qwy2g@2x.png"
            embracingChangeTheWorld="Embracing Change the World"
            evergreenBrands="Evergreen Brands"
            theParentCompanyWithOneFo="The parent company with one focus: make significant impact a reality."
            frame291="/frame-291@2x.png"
          />
          <EvergreenBrands
            unsplash9anj7QWy2g="/unsplashc5dlhukewfm@2x.png"
            embracingChangeTheWorld="National, Multi-Site Facility Operations"
            evergreenBrands="Transblue"
            theParentCompanyWithOneFo="Efficiently coordinating nationwide, multi-site facility operations that advance your business."
            frame291="/frame-291-1@2x.png"
            propMinHeight="48px"
            propHeight="30px"
          />
          <div className="condo-shield">
            <img
              className="unsplashm-hrflhgabo-icon"
              loading="eager"
              alt=""
              src="/unsplashm-hrflhgabo@2x.png"
            />
            <div className="frame-parent1">
              <div className="frame-parent2">
                <div className="association-management-and-mai-parent">
                  <div className="association-management-and">
                    Association Management and Maintenance
                  </div>
                  <div className="condo-shield1">Condo Shield</div>
                </div>
                <div className="your-partner-in">
                  Your partner in seamless association management and
                  maintenance for all HOAs.
                </div>
              </div>
              <div className="button-group">
                <button className="button37">
                  <div className="base19">
                    <img
                      className="masked-icon38"
                      alt=""
                      src="/masked-icon6.svg"
                    />
                    <div className="button38">Go there now</div>
                    <img
                      className="masked-icon39"
                      alt=""
                      src="/masked-icon-1.svg"
                    />
                  </div>
                </button>
                <div className="cs-logo-full-1-wrapper">
                  <img
                    className="cs-logo-full-1-icon"
                    loading="eager"
                    alt=""
                    src="/cs-logo-full-1@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mbridge-global">
            <img
              className="unsplashgwe0dlvd9e0-icon"
              loading="eager"
              alt=""
              src="/unsplashgwe0dlvd9e0@2x.png"
            />
            <div className="frame-parent3">
              <div className="frame-parent4">
                <div className="empowering-generosity-and-phil-parent">
                  <div className="empowering-generosity-and">
                    Empowering Generosity and Philanthropy
                  </div>
                  <div className="mbridge-global1">mBridge Global</div>
                </div>
                <div className="unlocking-your-philanthropic">
                  Unlocking your philanthropic potential, catalyzing generosity
                  to enhance our world.
                </div>
              </div>
              <div className="button-container">
                <div className="button39">
                  <div className="base20">
                    <img
                      className="masked-icon40"
                      alt=""
                      src="/masked-icon6.svg"
                    />
                    <div className="button40">Go there now</div>
                    <img
                      className="masked-icon41"
                      alt=""
                      src="/masked-icon-1.svg"
                    />
                  </div>
                </div>
                <img className="frame-child" alt="" src="/frame-291-2@2x.png" />
              </div>
            </div>
          </div>
          <Honey4Hope
            unsplash8V4yXXT3MQ="/unsplash8v4yxxt3mq@2x.png"
            sweetenTheWorldOneJarAtAT="Sweeten The World, One Jar at a Time"
            honey4Hope="Honey4Hope"
            everyDropEveryJarResonate="Every drop, every jar, resonates with our dedication to quality and charitable impact."
            honey4HopeHexLogo1="/honey4hope-hexlogo-1@2x.png"
          />
          <Honey4Hope
            unsplash8V4yXXT3MQ="/unsplashsavqflrm4do@2x.png"
            sweetenTheWorldOneJarAtAT="Expertise From Blue-Collar Experts"
            honey4Hope="Wescott Consulting Group"
            everyDropEveryJarResonate="Drawing from expertise to provide practical solutions that elevate your business."
            honey4HopeHexLogo1="/wcg-logo-1@2x.png"
            propWidth="92.9px"
          />
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
