import * as React from "react";
import { useCallback } from "react";
import HeaderMainNav from "../components/header-main-nav";
import FacebookButton from "../components/facebook-button";
import LetscChangeTheWorldFrame from "../components/letsc-change-the-world-frame";
import Podcasts from "../components/podcasts";
import Portfolio from "../components/portfolio";
import Footer from "../components/footer";
import "./index.css";
import { useLocation } from "@reach/router";

const HeaderFrame = () => {
  const location = useLocation();
  const portfolioRef = React.useRef(null);
  const podcastsRef = React.useRef(null);
  const scrollToPortfolio = useCallback(() => {
    window.scrollTo({
      top: portfolioRef.current.offsetTop,
      behavior: "smooth",
    });
  }, []);
  const scrollToPodcasts = useCallback(() => {
    window.scrollTo({
      top: podcastsRef.current.offsetTop,
      behavior: "smooth",
    });
  }, []);
  const onSpotifyListenBadge1ImageClick = useCallback(() => {
    window.open(
      "https://open.spotify.com/show/2cpZoILWiO5oiwIRpzdpYg?si=b9df6881dfe04bc8"
    );
  }, []);

  const onENGooglePodcastsBadgeClick = useCallback(() => {
    window.open(
      "https://podcasts.google.com/feed/aHR0cHM6Ly9tZWRpYS56ZW5jYXN0LmZtL3RlYWNoLW1lLWJlZXMvcnNz="
    );
  }, []);

  const onSpotifyListenBadge1Image1Click = useCallback(() => {
    window.open("https://open.spotify.com/show/1iY5bKIvTQCPvWQAwWyURs");
  }, []);

  const onENGooglePodcastsBadge1Click = useCallback(() => {
    window.open(
      "https://podcasts.google.com/feed/aHR0cHM6Ly9tZWRpYS56ZW5jYXN0LmZtL3RlYWNoLW1lLWJlZXMvcnNz="
    );
  }, []);

  const onFooterLogoClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='headerMainNav']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onBlogContainerClick = useCallback(() => {
    window.open("https://davewescott.com/blog", "_blank");
  }, []);

  React.useEffect(() => {
    console.log(location);
    if (location.pathname === "/podcasts") {
      scrollToPodcasts();
    } else if (location.pathname === "/portfolio") {
      scrollToPortfolio();
    }
  }, []);

  return (
    <div className="header-frame">
      <HeaderMainNav
        scrollToPortfolio={scrollToPortfolio}
        scrollToPodcasts={scrollToPodcasts}
      />

      <div className="lets-change-the-world">
        <FacebookButton />
        <div className="dave-social">
          <div className="wrapper-headshot-davewescottc">
            <img
              className="headshot-davewescottcom-icon"
              loading="eager"
              alt=""
              src="/headshot-davewescottcom@2x.png"
            />
          </div>
          <div className="social-media-card">
            <a
              className="button-linkedin"
              href="https://www.linkedin.com/in/thedavewescott/"
            >
              <div className="base">
                <img
                  className="social-icon"
                  loading="eager"
                  alt=""
                  src="/social.svg"
                />
                <img className="masked-icon" alt="" src="/masked-icon.svg" />
                <div className="button" />
                <img className="masked-icon1" alt="" src="/masked-icon.svg" />
              </div>
            </a>
            <a
              className="button-facebook"
              href="https://www.facebook.com/thedavewescott"
            >
              <div className="base1">
                <img className="social-icon1" alt="" src="/social-1.svg" />
                <img className="masked-icon2" alt="" src="/masked-icon.svg" />
                <div className="button1" />
                <img className="masked-icon3" alt="" src="/masked-icon.svg" />
              </div>
            </a>
            <a
              className="button-youtube"
              href="https://www.youtube.com/@thedavewescott"
            >
              <div className="base2">
                <img className="social-icon2" alt="" src="/social-2.svg" />
                <img className="masked-icon4" alt="" src="/masked-icon.svg" />
                <div className="button2" />
                <img className="masked-icon5" alt="" src="/masked-icon.svg" />
              </div>
            </a>
            <a
              className="button-instagram"
              href="https://www.instagram.com/thedavewescott/"
            >
              <div className="base3">
                <img className="social-icon3" alt="" src="/social-3.svg" />
                <img className="masked-icon6" alt="" src="/masked-icon.svg" />
                <div className="button3" />
                <img className="masked-icon7" alt="" src="/masked-icon.svg" />
              </div>
            </a>
            <a className="button-x" href="https://twitter.com/thedavewescott">
              <div className="base4">
                <img className="social-icon4" alt="" src="/social-4.svg" />
                <img className="masked-icon8" alt="" src="/masked-icon.svg" />
                <div className="button4" />
                <img className="masked-icon9" alt="" src="/masked-icon.svg" />
              </div>
            </a>
            <a
              className="button-tiktok"
              href="https://www.tiktok.com/@thedavewescott"
            >
              <div className="base5">
                <img className="social-icon5" alt="" src="/social-5.svg" />
                <img className="masked-icon10" alt="" src="/masked-icon.svg" />
                <div className="button5" />
                <img className="masked-icon11" alt="" src="/masked-icon.svg" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="god-family-then-business">
        <div className="god-family-business-frame">
          <div className="wrapper-rectangle-shape">
            <img
              className="rectangle-shape-icon"
              loading="eager"
              alt=""
              src="/rectangle-30@2x.png"
            />
          </div>
        </div>
        <LetscChangeTheWorldFrame />
      </div>
      <div ref={podcastsRef} />
      <Podcasts
        onSpotifyListenBadge1ImageClick={onSpotifyListenBadge1ImageClick}
        onENGooglePodcastsBadgeClick={onENGooglePodcastsBadgeClick}
        onSpotifyListenBadge1Image1Click={onSpotifyListenBadge1Image1Click}
        onENGooglePodcastsBadge1Click={onENGooglePodcastsBadge1Click}
      />
      <div ref={portfolioRef} />
      <Portfolio />
      <Footer
        onFooterLogoClick={onFooterLogoClick}
        onPortfolioContainerClick={scrollToPortfolio}
        onPodcastsContainerClick={scrollToPodcasts}
        onBlogContainerClick={onBlogContainerClick}
      />
    </div>
  );
};

export default HeaderFrame;
